import styled from '@emotion/styled';

export const Root = styled.div`
  margin-top: 8px;
  padding: 12px 16px;
  position: relative;
  color: #1f2b5e;
  border-bottom: 1px solid #e1e1e1;
  .down_carot {
    max-width: 16px;
    cursor: pointer;
    height: auto;
    path {
      stroke-width: 1.5;
      stroke: #1f2b5e;
    }
    :hover {
      path {
        stroke: #00b8d4;
      }
    }
  }
  .down_carot-disabled {
    max-width: 16px;
    color: lightgrey;
    :hover {
      color: lightgrey;
      cursor: initial;
    }
  }
  .kochav {
    max-width: 24px;
    height: auto;
    width: 100%;
    margin-right: 6px;
    flex: 0 0 24px;
  }
  a:hover {
    color: #00b8d4;
  }
  .relationship {
    font-size: 16px;
    color: #ec7258;
    font-weight: 600;
  }
  .name {
    font-size: 16px;
    font-weight: 700;
    color: #1f2b5e;
    line-height: 1.4;
    text-transform: capitalize;
  }
  .address {
    margin-top: 8px;
  }
  .all-info-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 16px;
    height: ${(props) => (props.isExpanded ? 'auto' : '0px')};
    overflow: hidden;
    transition: all 0.1s;
    > * {
      width: calc(50% - 8px);
      flex: 0 0 calc(50% - 8px);
      a {
        word-break: break-all;
      }
    }
  }
  .edit-contact {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-right: 16px;

    > * {
      height: 20px;
      position: relative;
      :before {
        position: absolute;
        top: 22px;
        white-space: nowrap;
        color: #000;
        text-transform: capitalize;
      }
      :hover:before {
        left: -22px;
        color: #fff;
        padding: 3px 6px;
        border-radius: 4px;
        background-color: #1f2b5e;
      }
    }
    .editIcon {
      svg {
        height: 18px;
        background-color: #1f2b5e;
        color: #fff;
        path {
          stroke-width: 1.2px;
        }
      }
      :hover {
        :before {
          content: 'Edit Contact';
        }
      }
    }
    .copy_email {
      svg {
        height: 18px;
        background-color: #1f2b5e;
        color: #fff;
      }
      :hover {
        :before {
          content: 'copy email';
        }
      }
    }
    .send_email {
      svg {
        height: 18px;
        background-color: #1f2b5e;
        color: #fff;
      }
      :hover {
        :before {
          content: 'send email';
        }
      }
    }
    .call_em {
      svg {
        height: 18px;
        background-color: #1f2b5e;
        color: #fff;
      }
      :hover {
        :before {
          left: -66px;
        }
      }
    }

    svg {
      cursor: pointer;
      transition: all 0.1s;
      height: 20px;
      width: auto;
      display: inline-flex;
      color: #ffffff;
      stroke-width: 1.5;
      padding: 3px;
      border-radius: 3px;
      outline: 2px solid #1f2b5e;
      path {
        stroke-width: 1.5;
      }
      :hover {
        background-color: #ffffff;
        color: #1f2b5e;
        path {
          color: #1f2b5e;
        }
      }
    }
  }
  .info-wrap {
    color: #1f2b5e;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    span {
      font-size: 12px;
      line-height: 18px;
    }
  }
  @media (min-width: 768px) {
    margin-top: 0;
    padding: 16px;
    .all-info-wrap {

    }
    .typeAt {
      font-size: 14px;
      color: #919EAB;
      text-align: right;
      font-style: italic;
    }
    .companyName {
      text-align: right;
      font-weight: 600;
      margin-top: 16px;
    }
    .addressFull {
      text-align: right;
      span {
        display: block;
      }
    }
    .relationship {
      font-size: 16px;
      white-space: nowrap;
    }
    .name {
      font-size: 14px;
    }
    .info-wrap {
      span {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .wasUpdatedWrapper {
    background-color: rgba(0, 216, 166, 0.2);
    border-radius: 10px;
    color: #006950;
    display: inline-flex;
    font-weight: 700;
    margin-top: 12px;
    padding: 10px;
    padding-bottom: 5px;
    .wasUpdatedColumn {
      margin-right: 6px;

      :last-of-type {
        margin-right: 0px;
        svg {
          fill: #006950;
          :hover {
            background-color: #f5fff7;
            cursor: pointer;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .wasRemovedWrapper {
    background-color: rgba(236, 114, 88, 0.2);
    border-radius: 10px;
    color: rgb(236, 114, 88);
    display: inline-flex;
    font-weight: 700;
    margin-top: 12px;
    padding: 10px;
    padding-bottom: 5px;
    .wasRemovedColumn {
      margin-right: 6px;
      :first-of-type {
        padding-top: 1.5px;
      }

      svg {
        color: rgb(236, 114, 88);
      }
      :last-of-type {
        margin-right: 0px;
        svg {
          fill: rgb(236, 114, 88);
          :hover {
            background-color: #f5fff7;
            cursor: pointer;
            border-radius: 50%;
          }
        }
      }
    }
  }
`;

export function Star({ ...props }) {
  return (
    <svg
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.04894 0.927052C6.3483 0.0057416 7.6517 0.00574088 7.95106 0.927052L8.79611 3.52786C8.92999 3.93989 9.31394 4.21885 9.74717 4.21885H12.4818C13.4505 4.21885 13.8533 5.45846 13.0696 6.02786L10.8572 7.63525C10.5067 7.8899 10.3601 8.34127 10.494 8.75329L11.339 11.3541C11.6384 12.2754 10.5839 13.0415 9.80017 12.4721L7.58779 10.8647C7.2373 10.6101 6.7627 10.6101 6.41222 10.8647L4.19983 12.4721C3.41612 13.0415 2.36164 12.2754 2.66099 11.3541L3.50604 8.75329C3.63992 8.34127 3.49326 7.8899 3.14277 7.63525L0.930391 6.02787C0.146677 5.45846 0.549452 4.21885 1.51818 4.21885H4.25283C4.68606 4.21885 5.07001 3.93989 5.20389 3.52786L6.04894 0.927052Z'
        fill='#FFAE00'
      />
    </svg>
  );
}

export function Building() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.6667 14V3.33333C12.6667 2.59695 12.0697 2 11.3333 2H4.66667C3.93029 2 3.33333 2.59695 3.33333 3.33333V14M12.6667 14L14 14M12.6667 14H9.33333M3.33333 14L2 14M3.33333 14H6.66667M6 4.66665H6.66667M6 7.33332H6.66667M9.33333 4.66665H10M9.33333 7.33332H10M6.66667 14V10.6667C6.66667 10.2985 6.96514 9.99999 7.33333 9.99999H8.66667C9.03486 9.99999 9.33333 10.2985 9.33333 10.6667V14M6.66667 14H9.33333'
        stroke='#111827'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
